/* You can add global styles to this file, and also import other style files */
@use 'anchor';
@use 'buttons';
@use 'select';
@use 'snackbar';
@use 'common';
@use 'inputs';
@use 'flag';
@use 'map';
@use './../../../libs/animation/src/lib/p-skeleton/skeleton.component';
@import 'cookieconsent';
@import 'custom_bootstrap';
@import 'custom_icon_font';
@import 'swiper/swiper-bundle.min.css';

:root {
  --swiper-theme-color: var(--bs-pink);
  --swiper-navigation-color: var(--bs-white);
}

html {
  /* sticky elem miatt a body nem lehet 100% height */
  height: 100%;
}

html,
body {
  min-height: 100%;
}

svg {
  vertical-align: unset !important;
}

///* Importing Datepicker SCSS file. */
//@import "~ngx-bootstrap/datepicker/bs-datepicker";
